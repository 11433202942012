import * as $ from "jquery";
import { passwordFieldToggle } from "./components/password-field-toggle";

localStorage.removeItem("banner_dismissed");

let bodyId = $("body").attr("id");
let APP_RESET_PASSWORD = "app-account-reset-password";
if (["app-account-login", APP_RESET_PASSWORD].includes(bodyId)) {
    if (bodyId !== APP_RESET_PASSWORD) {
        firebase.initializeApp(firebaseConfig);
        firebase.auth().useDeviceLanguage();

        const uiConfig = {
            signInOptions: [
                // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
                // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
                "microsoft.com",
            ],
            signInFlow: "popup",
            callbacks: {
                signInSuccessWithAuthResult: function (authResult) {
                    $(".loader").show();
                    const providerId = authResult.additionalUserInfo.providerId;
                    getUserToken(authResult.user, providerId);
                    return true;
                },
                signInFailure: function (error) {
                    console.log(error);
                    // Some unrecoverable error occurred during sign-in.
                    // Return a promise when error handling is completed and FirebaseUI
                    // will reset, clearing any UI. This commonly occurs for error code
                    // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
                    // occurs. Check below for more details on this.
                    return handleUIError(error);
                },
            },
        };

        const ui = new firebaseui.auth.AuthUI(firebase.auth());
        ui.start("#firebaseui-auth-container", uiConfig);

        let getUserToken = function (user, providerId) {
            user.getIdToken(true).then((token) => {
                localStorage.removeItem("token");
                localStorage.setItem("firebaseToken", token);
                window.location = `${window.origin}/app/login/firebase?token=${token}&providerId=${providerId}`;
            });
        };

        let userLogout = function () {
            firebase.auth().signOut();
            return false;
        };

        $("#firebaseui-auth-container").bind("DOMSubtreeModified", function () {
            if ($("#firebaseui-auth-container").find(".firebaseui-id-page-password-linking").length > 0) {
                $(".loader").hide();
                $("#username-login-groups").hide();
                $("#back-to-login").show();
            }
        });

        window.addEventListener("load", function () {
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    if (FORCE_LOGOUT) {
                        return userLogout();
                    }
                    let providerId = user.providerData.shift().providerId;
                    if (providerId === "facebook.com") {
                        $(".loader").show();
                        getUserToken(user, providerId);
                        removeAllDb();
                    }
                }
            });
        });
    }

    $("#back-to-login").click(() => {
        $("#username-login-groups").show();
        $(this).hide();
    });

    const removeAllDb = () => {
        const indexedDB = window.indexedDB || window.webkitIndexedDB || window.mozIndexedDB || window.msIndexedDB;
        window.indexedDB.deleteDatabase("fbase_key");
        indexedDB
            .databases()
            .then((r) => {
                for (var i = 0; i < r.length; i++) indexedDB.deleteDatabase(r[i].name);
            })
            .then(() => {});
    };

    $("body#fos-user-security-login .login-db").click((event) => {
        $(".loader").show();
        localStorage.removeItem("token");
        $("#login-form").submit();
        event.preventDefault();
    });

    passwordFieldToggle();
}
